import React from 'react';
import Container from '../components/Container';
import SEO from '../components/seo';
import NosServices from '../components/PagesComponent/NosServices';

function DeveloppementPage() {
  return (
    <Container>
      <SEO
        title="Hairun Technology - Développement sur mesure"
        description="HaiRun Technology est une entreprise de développement web sur mesure qui utilise la nouvelle technologie de FullStack JS dans la réalisation de vos projets digitaux. Notre motivation c'est de vous satisfaire dans la conception de votre site web et application web."
      />
      <NosServices />
    </Container>
  );
}

export default DeveloppementPage;
